// Text
$default-font: 'Raleway', sans-serif
$secondary-font: 'Lora', serif
$default-font-size: 16rem
$line-height: 1.2

// Colors
$c-text: #1f1f1f
$c-text-s: #030303
$c-accent: #f73400
$c-accent-hover: #1f2641
$c-gray: #58595b
$c-light-gray: #f5f5f5
$c-border: #dadada
$c-red: #e83041
$c-blue: #1f2641
$c-blue2: #3d45ab
$c-green: #52b85a
$c-input-border: #b3b3b3

// Default transition duration
$trs-dur: 0.3s

// Sizes
$rem: 1px
$rem-xl: .9px
$rem-mobile: 0.75px

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px
$grid-columns: 12

// Media sizes
$xs: 359.98px
$sm: 575.98px
$md: 767.98px
$lg: 991.98px
$xl: 1199.98px
$xxl: 1439.98px

$sm-c: 570px
$md-c: 755px
$lg-c: 970px
$xl-c: 1170px
$xxl-c: 1430px

$grid-breakpoints: (xs: 0, sm: $sm, md: $md, lg: $lg, xl: $xl, xxl: $xxl)
$container-max-widths: (sm: $sm-c, md: $md-c, lg: $lg-c, xl: $xl-c, xxl: $xxl-c)
$gutter: $grid-gutter-width
