@import "vars"
@import "mixins"

html
    font-family: 'Raleway', sans-serif

body
    margin: 0
    color: #030303

*,
*::before,
*::after
    box-sizing: border-box
    font-family: inherit

+rmin($sm)
    .ai-container, .ai-container-sm
        max-width: 570px

+rmin($md)
    .ai-container, .ai-container-md, .ai-container-sm
        max-width: 755px

+rmin($lg)
    .ai-container, .ai-container-lg, .ai-container-md, .ai-container-sm
        max-width: 970px

+rmin($xl)
    .ai-container, .ai-container-lg, .ai-container-md, .ai-container-sm, .ai-container-xl
        max-width: 1170px

+rmin($xxl)
    .ai-container, .ai-container-lg, .ai-container-md, .ai-container-sm, .ai-container-xl, .ai-container-xxl
        max-width: 1430px

.ai-container, .ai-container-fluid, .ai-container-lg, .ai-container-md, .ai-container-sm, .ai-container-xl, .ai-container-xxl
    width: 100%
    padding-right: var(--bs-gutter-x,15px)
    padding-left: var(--bs-gutter-x,15px)
    margin-right: auto
    margin-left: auto

.breadcrumbs
    font-size: 13px
    font-family: $secondary-font
    padding: 34px 0 20px
    &-item
        &:not(:last-child)
            padding-left: 5px
            &::after
                font-family: 'whisky-list' !important
                line-height: 1
                content: "\e93f"
                font-size: 8px
                padding-left: 5px
        &-current, span
            color: $c-gray

.ai-columns
    display: flex
    justify-content: space-between
    flex-wrap: wrap

.ai-flex-center
    align-items: center

.ai-color-gray
    color: #58595b

.ai__h3
    font-size: 28px
    margin-bottom: 1em
    font-weight: 500
    +r($xl)
        font-size: 24px
    +r($lg)
        font-size: 22px
    +r($md)
        font-size: 20px
    +r($sm)
        font-size: 18px

.ai
    img
        max-width: 100%
    &__header
        border-bottom: 1px solid #dadada
        padding-top: 25px
        padding-bottom: 25px
        +r($md)
            padding-top: 15px
            padding-bottom: 15px
        a
            display: block
        .ai-container
            display: flex
            align-items: center
            justify-content: space-between
        .ai__button
            border-radius: 5px
            font-size: 16px
            padding: 0.5em 1.5em
            +r($md)
                font-size: 14px
                padding: 0.5em 1em
            +r($sm)
                font-size: 12px
    &__logo
        max-width: 300px
        +r($md)
            max-width: 200px
        +r($xs)
            max-width: 175px
    &__title
        font-size: 48px
        font-weight: 700
        text-align: center
        margin-bottom: 1em
        margin-top: 1em
        +r($xl)
            font-size: 44px
        +r($lg)
            font-size: 40px
        +r($md)
            font-size: 36px
        +r($sm)
            font-size: 32px
    &__intro
        width: 100%
        img
            width: 100%
    &__section-title
        font-size: 38px
        font-weight: 500
        margin-top: 0
        margin-bottom: 0
        +r($xl)
            font-size: 36px
        +r($lg)
            font-size: 32px
        +r($md)
            font-size: 28px
        +r($sm)
            font-size: 24px
    &__section-subtitle
        font-size: 16px
        font-style: italic
        font-weight: 500
    &__text
        font-size: 16px
        line-height: 1.6em
        color: #58595b
        p + p
            margin-top: 1em
    &__button
        max-width: 470px
        font-size: 20px
        font-weight: 600
        font-family: Lora,serif
        display: inline-block
        text-decoration: none
        text-align: center
        padding: .8em 2em
        border: 2px solid #f73400
        background-color: #f73400
        color: #fff
        border-radius: 30px
        transition: all .25s linear
        &--full
            width: 100%
        &--centered
            margin-left: auto
            margin-right: auto
        &:hover, &:focus, &:active
            background-color: #fff
            color: #f73400
            text-decoration: none
        +r($lg)
            font-size: 18px
        +r($md)
            font-size: 16px

    &__advantages
        padding: 4% 5%
        background-image: url(../img/whisky-handpicked.jpeg)
        background-size: cover
        color: #fff
        position: relative
        width: 685px
        &::after
            content: ''
            display: block
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0
            z-index: 1
            background-image: radial-gradient(circle 304px at center,rgba(247,52,0,0.76) 0,#f73400 300%)
        +r($xxl)
            width: 600px
        +r($xl)
            width: 500px
        +r($lg)
            width: 100%
            order: 0
    &__adv-item
        display: flex
        align-items: center
        justify-content: space-between
        position: relative
        z-index: 9
        font-size: 30px
        margin-bottom: 1.5em
        &:last-child
            margin-bottom: 0
        +r($xl)
            font-size: 24px
        +r($lg)
            font-size: 22px
            justify-content: flex-start
        +r($md)
            font-size: 20px
        +r($sm)
            font-size: 18px
        &__icon
            width: 4.5em
            height: 4.5em
            border-radius: 50%
            border: 3px solid currentcolor
            display: flex
            align-items: center
            justify-content: center
            flex-shrink: 0
            padding: 1em
            order: 1
            +r($lg)
                order: 0
        &__title
            padding-left: 1em
            font-size: 1em
            order: 0
            +r($lg)
                order: 1
                br
                    display: none
    &__personalised
        width: 575px
        +r($xxl)
            width: calc(100% - 640px)
        +r($xl)
            width: calc(100% - 530px)
        +r($lg)
            width: 100%
            margin-top: 30px
            order: 1

.ai-advantages-section
    padding-top: 50px
    padding-bottom: 50px

.ai-testimonials
    padding-top: 50px
    padding-bottom: 50px
    background-color: #e5e5e5
    &__header
        display: flex
        justify-content: space-between
        svg
            transition: all .25s linear
            &:hover
                fill: #f73400
        &__r
            display: flex
            align-items: flex-end
            .testimonial-slide-next,
            .testimonial-slide-prev
                cursor: pointer
            .testimonial-slide-prev
                margin-right: 20px
                svg
                    transform: rotate(180deg)
    &__slider
        margin-top: 55px
        +r($lg)
            margin-top: 40px

        .testimonial-item
            background-color: #f8f8f8
            padding: 20px
            position: relative
            &::before
                content: ''
                display: block
                width: 45px
                height: 34px
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='45' height='34' viewBox='0 0 45 34'><g><g><path fill='%23f73400' d='M20.25 23.626c0 5.592-4.533 10.125-10.125 10.125S0 29.218 0 23.626C0 3.097 13.392.181 14 .07c1.778-.373 3.562.807 3.935 2.635A3.378 3.378 0 0 1 15.3 6.688 10.527 10.527 0 0 0 9.261 12c-.168.34-.163.713.015 1 .177.315.5.5.849.501A10.103 10.103 0 0 1 20.25 23.626zm14.625-10.125A10.103 10.103 0 0 1 45 23.626c0 5.592-4.533 10.125-10.125 10.125S24.75 29.218 24.75 23.626C24.75 3.097 38.129.181 38.7.07a3.378 3.378 0 0 1 3.204 1.07c.8.861 1.096 2.165.675 3.31-.383 1.145-1.347 1.998-2.579 2.24A10.64 10.64 0 0 0 34 12c-.157.34-.152.713 0 1 .203.315.526.5.875.501z'/></g></g></svg>")
                background-size: 100% 100%
                margin-bottom: 20px
            &__text
                font-size: 18px
                line-height: 1.75
                font-weight: 600
                font-style: italic
                font-family: Lora,serif
                min-height: 175px
            &__author
                border-top: 1px solid #afafaf
                margin-top: 25px
                padding-top: 10px
                padding-bottom: 10px
                font-size: 22px
                font-weight: 700
            &__footer
                display: flex
                justify-content: space-between
                align-items: flex-end
                &__l
                    width: calc(100% - 170px)
            &__image
                max-width: 150px
                flex-shrink: 0

.ai-benefits
    color: #fff
    background-image: url(../img/member-benefits-whisky.jpeg)
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    padding-top: 50px
    padding-bottom: 50px
    position: relative
    text-align: center
    &::after
        content: ''
        display: block
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        z-index: 1
        background-image: radial-gradient(circle 304px at center,rgba(247,52,0,0.76) 0,#f73400 300%)
    .ai-container
        position: relative
        z-index: 9
    &__list
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        margin-top: 50px
    &__item
        position: relative
        width: 33.3%
        padding-left: 3%
        padding-right: 3%
        +r($xxl)
            padding-left: 2%
            padding-right: 2%
        +r($md)
            width: 100%
            padding-left: 0
            padding-right: 0
        &:not(:first-child)
            &::before
                content: ''
                display: block
                position: absolute
                width: 1px
                height: 100%
                left: 0
                top: 0
                background-color: #f5f5f5
                +r($md)
                    width: 30%
                    height: 1px
                    padding-left: 0
                    padding-right: 0
                    position: static
                    margin-top: 35px
                    margin-bottom: 35px
                    margin-left: auto
                    margin-right: auto
        &__icon
            height: 74px
            svg
                max-height: 100%
            +r($md)
                height: 60px
        &__title
            font-family: Lora,serif
            font-size: 22px
            font-weight: 400
            margin-top: 25px
            line-height: 1.4
            +r($xxl)
                font-size: 20px
            +r($xl)
                font-size: 18px

.ai-how-it-works
    text-align: center
    padding-top: 20px
    padding-bottom: 50px

.ai-hiw
    background-color: #f9e2de
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    padding-top: 50px
    padding-bottom: 50px
    margin-top: 50px
    +r($lg)
        padding-top: 30px
        padding-bottom: 30px
    &__item
        position: relative
        width: 33.3%
        padding-left: 3%
        padding-right: 3%
        display: flex
        align-items: flex-start
        +r($xxl)
            padding-left: 2%
            padding-right: 2%
        +r($lg)
            padding-left: 15px
            padding-right: 15px
            width: 100%
            flex-wrap: wrap
        &:not(:first-child)
            &::before
                content: ''
                display: block
                position: absolute
                width: 1px
                height: 100%
                left: 0
                top: 0
                background-color: #dadada
                +r($lg)
                    width: 100%
                    height: 1px
                    padding-left: 0
                    padding-right: 0
                    position: static
                    margin-top: 30px
                    margin-bottom: 30px
                    margin-left: auto
                    margin-right: auto
    &__no
        font-size: 40px
        width: 1.5em
        height: 1.5em
        flex-shrink: 0
        border-radius: 50%
        color: #f73400
        font-family: "Lora - Regular"
        border: 3px solid #f73400
        display: flex
        align-items: center
        justify-content: center
        +r($xxl)
            font-size: 30px
        +r($md)
            font-size: 25px
    &__content
        width: calc(100% - 100px)
        text-align: left
        margin-left: 40px
        +r($xxl)
            width: calc(100% - 80px)
            margin-left: 20px
        +r($sm)
            width: 100%
            margin-left: 0
            margin-top: 20px
    &__title
        font-size: 18px
        font-weight: 700
        +r($md)
            font-size: 16px
    &__text
        font-size: 16px
        margin-top: 5px
        color: #58595b
        line-height: 1.6
        +r($md)
            font-size: 14px

.ai-get-started
    padding-top: 40px
    padding-bottom: 50px
    text-align: center

.ai-gs
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    margin-top: 75px
    &__item
        position: relative
        width: 33.3%
        padding-left: 3%
        padding-right: 3%
        display: flex
        align-items: flex-start
        flex-wrap: wrap
        justify-content: center
        +r($xxl)
            padding-left: 2%
            padding-right: 2%
        +r($lg)
            padding-left: 15px
            padding-right: 15px
            width: 100%
            flex-wrap: wrap
        &:not(:first-child)
            &::before
                content: ''
                display: block
                position: absolute
                width: 1px
                height: 200px
                left: 0
                top: 0
                background-color: #dadada
                +r($lg)
                    width: 20%
                    height: 1px
                    padding-left: 0
                    padding-right: 0
                    position: static
                    margin-top: 30px
                    margin-bottom: 30px
                    margin-left: auto
                    margin-right: auto
    &__content
        width: 100%
    &__icon
        font-size: 100px
        width: 2.2em
        height: 2.2em
        background-color: #f9e2de
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        margin-left: auto
        margin-right: auto
        svg
            max-width: 1em
            max-height: 1em
        +r($xxl)
            font-size: 80px
        +r($xl)
            font-size: 70px
        +r($lg)
            font-size: 60px
    &__title
        width: 100%
        margin-top: 30px
        font-size: 28px
        +r($xxl)
            font-size: 24px
        +r($lg)
            font-size: 20px
    &__subtitle
        width: 100%
        font-size: 18px
        text-align: center
        margin-top: 10px
        color: #58595b
        +r($lg)
            font-size: 16px

.ai-does-it-work
    background-color: #f5f5f5
    padding-top: 50px
    padding-bottom: 50px
    text-align: center

.ai-diw
    &__content
        display: flex
        flex-wrap: wrap
        margin-top: 50px
        margin-bottom: 50px
        justify-content: center
    &__left
        width: 560px
        +r($xxl)
            width: 400px
        +r($xl)
            width: 325px
        +r($lg)
            width: 100%
            max-width: 400px
    &__right
        width: calc(100% - 620px)
        margin-left: 60px
        text-align: left
        +rmin($lg)
            h4:first-child
                margin-top: 0
        +r($xxl)
            width: calc(100% - 430px)
            margin-left: 30px
        +r($xl)
            width: calc(100% - 350px)
            margin-left: 25px
        +r($lg)
            width: 100%
            margin-left: 0
            margin-top: 30px
        p
            font-size: 16px
            margin-bottom: 2em
            line-height: 1.8
            color: #58595b
            +r($xl)
                font-size: 14px
    &__after
        width: 100%

.sneak-peek
    padding-top: 60px
    padding-bottom: 40px
    text-align: center
    +r($lg)
        text-align: left
        padding-top: 40px
.sp-box
    background-color: #f9e2de
    padding: 20px 30px
    +r($xl)
        padding: 15px 20px
    &__list
        margin-top: 60px
        display: grid
        grid-template-columns: repeat(3, 1fr)
        gap: 5%
        +r($xxl)
            gap: 15px
            margin-top: 50px
        +r($lg)
            margin-top: 40px
            grid-template-columns: repeat(1, 1fr)
    &__title
        font-size: 22px
        min-height: 80px
        margin-top: 10px
        margin-bottom: 0
        font-weight: 500
        +r($xxl)
            font-size: 20px
        +r($xl)
            font-size: 18px

    &__content
        text-align: left
        ul
            padding-left: 15px

            li
                font-size: 18px
                padding-top: .5em
                padding-bottom: .5em
                +r($xxl)
                    font-size: 16px
                +r($xl)
                    font-size: 14px
    &__heart
        display: block // in case it will be an a
        flex-shrink: 0
        width: 76px
        height: 76px
        border: 3px solid #f73400
        border-radius: 50%
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='38' height='34' viewBox='0 0 38 34'><g><g><path fill='none' stroke='%23f73400' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='20' stroke-width='3' d='M32.76 4.653a8.578 8.578 0 0 0-12.405 0l-1.691 1.743v0L17 4.653c-3.3-3.426-8.732-3.548-12.158-.274a9.258 9.258 0 0 0-.273 13.074l1.683 1.743v0L18.664 32v0l12.405-12.8v0l1.69-1.745a9.255 9.255 0 0 0 0-12.8z'/></g></g></svg>")
        background-size: 38px 34px
        background-position: center
        background-repeat: no-repeat
        +r($xl)
            width: 50px
            height: 50px
            border: 2px solid #f73400
            background-size: 27px 24px

    &__footer
        display: flex
        align-items: flex-end
        justify-content: space-between
        text-align: left
    &__image
        width: 200px
        height: 150px
        text-align: right
        img
            max-width: 100%
            height: 180px
    &__name
        display: block // in case it will be an a
        text-align: left
        margin-top: 10px
        color: #f73400
        font-size: 22px
        +r($xxl)
            font-size: 20px
        +r($xl)
            font-size: 18px
        span
            display: block
        strong
            display: block
            margin-top: 5px

.ai-faq
    padding-top: 40px
    padding-bottom: 40px
    &__tabs
        display: flex

        &-panels
            font-size: 16px
            color: #58595b
            line-height: 1.6
            border-left: 1px solid #dadada
            padding-left: 50px
            +r($xxl)
                padding-left: 30px
            +r($xl)
                padding-left: 25px

.ootb-tabcordion
    background: #fff
    margin: 40px auto 25px auto
    border-radius: 3px
    display: flex
    // align-items: flex-start

.ootb-tabcordion.has-tabs .ootb-tabcordion--tabs
    opacity: 1
    height: auto
    visibility: visible

.ootb-tabcordion.has-tabs .ootb-tabcordion--entry
    min-height: 0

.ootb-tabcordion.has-tabs .ootb-tabcordion--entry::before
    display: none

.ootb-tabcordion.has-tabs .ootb-tabcordion--entry.is-active .ootb-tabcordion--entry-content
    opacity: 1
    transition: opacity 400ms ease-in-out

.ootb-tabcordion.has-tabs .ootb-tabcordion--entry .ootb-tabcordion--entry-content
    opacity: 0
    transition: opacity 400ms ease-in-out

.ootb-tabcordion--tabs
    display: flex
    align-items: flex-start
    flex-direction: column
    margin: 0
    list-style: none
    padding: 0 15px 0 0
    width: 500px
    flex-shrink: 0
    +r($xxl)
        width: 450px
    +r($xl)
        width: 400px
    +r($lg)
        width: 0
    button
        display: block

    +r($lg)
        opacity: 0
        height: 0
        visibility: hidden
        position: absolute

.ootb-tabcordion--panels
    padding-left: 50px
    font-size: 16px
    line-height: 1.6
    color: #58595b
    border-left: 1px solid #dadada
    +r($xxl)
        padding-left: 30px
    +r($xl)
        padding-left: 25px
    +r($lg)
        width: 100%
        padding-left: 0
        border-left: none

.ootb-tabcordion--tabs .tab
    display: block
    border: none
    cursor: pointer
    width: 100%
    font-size: 28px
    font-weight: 500
    line-height: 1.4
    text-align: left
    padding-top: .5em
    padding-bottom: .5em
    padding-right: 0.5em
    background-color: #fff
    color: #58595b

    +r($xxl)
        font-size: 24px
    +r($xl)
        font-size: 18px
    +r($sm)
        font-size: 16px
    +r($md)
        color: #fff

.ootb-tabcordion--tabs .tab:hover, .ootb-tabcordion--tabs .tab:focus
    outline: none

.ootb-tabcordion--tabs .tab.is-active
    color: #f73400

.ootb-tabcordion--tabs .tab.is-active:hover, .ootb-tabcordion--tabs .tab.is-active:focus
    // background: #1c9fff

.ootb-tabcordion--entry
    overflow: hidden

.ootb-tabcordion--entry::before
    position: relative
    content: attr(data-title)
    cursor: pointer
    z-index: 1
    background: #1f1f1f
    color: #fff
    padding: 10px 15px
    border-radius: 3px
    margin-top: 3px
    margin-bottom: 3px
    display: none
    +r($lg)
        display: block

.ootb-tabcordion--entry:hover, .ootb-tabcordion--entry:focus
    outline: none

.ootb-tabcordion--entry:hover::before, .ootb-tabcordion--entry:focus::before
    background: #535353

.ootb-tabcordion--entry.is-active .ootb-tabcordion--entry-content
    margin-top: 0
    height: auto
    opacity: 1
    line-height: 1.75
    transition: margin 400ms ease-out -100ms
    p
        margin-bottom: 1em

.ootb-tabcordion--entry.is-active::before
    background: #f73400

.ootb-tabcordion--entry.is-active:hover::before, .ootb-tabcordion--entry.is-active:focus::before
    background: #f73400

.ootb-tabcordion--entry-container
    overflow: hidden
    margin-bottom: 1px

.ootb-tabcordion--entry-content
    position: relative
    margin-top: -100%
    height: 0
    opacity: 0
    transition: margin 500ms ease-in
    padding: 10px 15px

.ootb-tabcordion--entry-content a.more
    color: #444

.ootb-tabcordion--entry-content a.more:hover
    color: #3bacff

.ai-footer
    margin-top: 50px
    &__border
        border-top: 1px solid $c-border
        +r($md)
            border-top: none

    &__socialrow
        height: 105px
        +r($xl)
            height: 80px
        +r($md)
            border-top: none
            height: auto
        .ai-container
            display: flex
            height: 100%
            +r($md)
                flex-wrap: wrap
        &__payments
            display: flex
            align-items: center
            width: 50%
            border-right: 1px solid $c-border
            +r($md)
                flex-wrap: wrap
                width: 100%
                order: 1
                border-right: none
                padding-top: 15px
            .payment-icon
                background: url('../img/payments-icons-non-retina.png') no-repeat top left
                width: 133px
                height: 104px
                margin-left: -28px
                margin-right: -15px
                transform: scale(.8)
                @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
                    background: url('../img/payments-icons-retina.png') no-repeat top left
                    background-size: 938px 104px
                +r($xxl)
                    margin-left: -38px
                    margin-right: -20px
                    transform: scale(.65)
                +r($xl)
                    background-size: 469px 52px
                    width: 66px
                    height: 52px
                    margin-left: -15px
                    margin-right: 0
                    transform: scale(.9)
                    +r($md)
                        margin-left: -10px
                        transform: none
            .payment-icon.amex-logo
                background-position: 0 0
            .payment-icon.apple-pay
                background-position: -134px 0
                +r($xl)
                    background-position: -67px 0
            .payment-icon.google-pay-logo
                background-position: -268px 0
                +r($xl)
                    background-position: -134px 0
            .payment-icon.mastercard-logo
                background-position: -402px 0
                +r($xl)
                    background-position: -201px 0
            .payment-icon.o-pay-logo
                background-position: -536px 0
                +r($xl)
                    background-position: -268px 0
            .payment-icon.pay-pal-logo
                background-position: -670px 0
                +r($xl)
                    background-position: -335px 0
            .payment-icon.visa-logo
                background-position: -804px 0
                +r($xl)
                    background-position: -402px 0

        &__accounts
            width: 50%
            padding-left: 83px
            display: flex
            justify-content: flex-end
            +r($xl)
                padding-left: 50px
            +r($lg)
                padding-left: 25px
            +r($md)
                width: 100%
                border-top: 1px solid $c-border
                padding-left: 0
                flex-wrap: wrap
                padding-top: 35px
                margin-bottom: 10px
                justify-content: flex-start

            &-smedia
                display: flex
                justify-content: flex-end
                +r($sm)
                    width: 100%
                    justify-content: flex-start
                &-icon
                    display: flex
                    align-items: center
                    height: 100%
                    font-size: 16px
                    svg
                        transition: all .25s linear
                    &:not(:first-child)
                        margin-left: 23px
                    &:hover
                        svg
                            fill: #f73400

    &__copyright
        display: flex
        align-items: flex-start
        justify-content: space-between
        padding-top: 40px
        padding-bottom: 20px
        +r($md)
            padding-top: 20px
        &-left
            width: calc(100% - 120px)
            max-width: 1017px
            color: $c-gray
            font-size: 13px
            line-height: 1.86
            letter-spacing: -0.16px
            +r($md)
                width: 100%
                letter-spacing: initial
                padding-top: 40px
                border-top: 1px solid $c-border
            p
                margin-bottom: 23px
        &-right
            width: 100px
            a
                display: block
            +r($md)
                display: none
    img
        width: 100%

.ai-button-row
    text-align: center
    width: 100%
    margin-top: 25px

.mt-25
    margin-top: 25px

.mb-25
    margin-bottom: 25px
